$fontDir: "./node_modules/@fontsource/lato";
@import "./node_modules/@fontsource/lato/index.css";

$web-font-path: false;
@import "bootswatch/dist/darkly/_variables";
@import "bootstrap/scss/bootstrap";
@import "bootswatch/dist/darkly/_bootswatch";

$fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/solid";
